/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/camelcase */

// About interface

interface Iapidata<T> {
  data: T;
}

interface TDataPoint {
  x1Value: number;
  x2Value: number;
  y1Value: number;
  y2Value: number;
}

interface TNote {
  id: string;
  diagnosis_id: string;
  x1: string;
  x2: string;
  channel: string;
  note: string;
}
import { reactive, ref, computed } from "vue";

export const token = ref(localStorage.getItem("access_token")) ?? ref("");
export const isLogin =
  ref(JSON.parse(localStorage.getItem("isLogin"))) || ref(false);
export const page = ref({
  diagnoses: 1,
  remove: 1,
});
export const dbNum: any = ref("v2") ?? ref(localStorage.getItem("dbNum"));

export const tableData = reactive({
  data: [],
});
export const diagnosesList = computed(() => {
  tableData.data.forEach((item, index) => {
    if (dbNum.value === "v1") {
      item.create_at = new Date(item.create_at).toLocaleString();
    } else if (dbNum.value === "v2") {
      item.create_date = new Date(item.create_date).toLocaleString();
    }
    // item.index =
    // 	page.value.diagnoses * 10 -
    // 	9 +
    // 	index;
    // console.log(item.notes);

    // item.notes =
    // 	item.notes.length !== 0
    // 		? item.notes
    // 				.reduce(
    // 					(acc, current) =>
    // 						acc + ', ' + current
    // 				)
    // 				.replace(
    // 					/(\[|]|")*/g,
    // 					''
    // 				)
    // 		: '尚未標記';
    // if (item.notes.length !== 0) {
    // 	console.log(
    // 		item.notes
    // 			.reduce(
    // 				(acc, current) =>
    // 					acc + ', ' + current
    // 			)
    // 			.replace(/(\[|]|")*/g, '')
    // 	);
    // 	item.notes = item.notes
    // 		.reduce(
    // 			(acc, current) =>
    // 				acc + ', ' + current
    // 		)
    // 		.replace(/(\[|]|")*/g, '');
    // } else {
    // 	console.log('尚未標記');
    // 	item.notes = '尚未標記';
    // }
  });
  return tableData.data;
});
export const role = ref("");
export const diagnoses: Iapidata<object> = reactive({
  data: [],
  length,
});
export const modelName = reactive([]);

export const selectedModelName = ref("");

export const anomalyData = reactive({
  data: [],
});
export const evaluationData = reactive({
  data: [],
});
export const filterAnomalyData = reactive([]);
export const filterEvaluationData = reactive([]);

export const countNumber = ref(0);

export const anomalyMode = ref(false);
export const anomalySequence = ref([]);
export const evaluationTags = ref([]);
export const anomalyModels = ref([]);

export const selectedPoints: TDataPoint[][] = reactive([]);
export const noteMode = ref("ST-E");
export const tagMode = computed(() => {
  return noteMode.value;
});
export const tagListData: any = reactive({
  data: [],
});
export const tagList: any = reactive({
  data: [],
});

export const selectTagData: any = reactive({
  data: [
    {
      index: 0,
      channel: "",
      created_at: "",
      diagnosis_id: "",
      note: "",
      x1: "",
      x2: "",
    },
  ],
});
export const tagDataShow = computed(() => {
  return selectTagData.data;
});

export const evaluationTagData: any = reactive({
  data: [
    {
      channel: 0,
      evaluator: "",
      evaluation: "",
      model_name: "",
      x1: 0,
      x2: 0,
    },
  ],
});
export const evaluationTagDataShow = computed(() => {
  console.log(console.log(anomalyData.data[1]));
  return evaluationTagData.data;
});

export const reportData = ref({
  data: [],
  length,
});
// export const reportDataShow = computed(
// 	() => {
// 		return reportData.data;
// 	}
// );

export const isChecked = ref(false);
export const isEvaluated = ref(false);
export const isActive = computed(() => {
  return isChecked.value;
});

export const selectTagIndex = ref(0);

export const timer = ref(0);
export const diagnosesid: any = ref("");

export const isLoading = ref(false);

export const events = ref([
  "click",
  "mousemove",
  "mousedown",
  "scroll",
  "keypress",
  "load",
]);

export const warningTimer = ref(null);
export const logoutTimer = ref(null);

export const captChaCode = ref("");
export const veriFied = ref(false);
export const selectPhone = ref(sessionStorage.getItem("selectPhone"));
export const selectPhoneRemove = ref(
  sessionStorage.getItem("selectPhoneRemove")
);
export const insertTagName = ref("");
export const userphone = ref("");
export const adminDataTable = ref([]);
export const languageList = ref([]);
export const healthCareListTable = ref([]);
export const testRef = ref(null);
export const addCommentName = ref("");

export const suggestionDataIdx = ref(0);

export const newLang = ref("");

export const newSuggestion = ref({
  lang: "",
  suggestions: "",
});

export const selectLangIdx = ref(0);
export const newCommentName = ref("");
export const tagIdx = ref(0);

export const modalData = async (e) => {
  tagIdx.value = e;
};

export const attributes = ref([]);

// export const phone = ref(null);
export const diagnosesNameShow = ref(false);
export const calendarNameShow = ref(false);
export const adminNameShow = ref(false);

export const btnDisabled = ref(true);

export const articleList = ref({
  counts: 0,
  posts: [],
});
export const tempArticleContent = ref({
  post_id: "",
  title: "",
  category: "",
  content: "",
  lang: "",
  cover_image: "",
  cover_image_link: "",
  attachments: [],
  create_user: "",
  // JSON.parse(
  // 	localStorage.getItem("userinfo")
  // ).user_id || ""
});
export const tempImgAry = ref([]);
export const imgAry = ref([]);
export const deleteImgAry = ref([]);
export const removeImgAry = ref([]);
export const imageList = ref([]);
export const categories = ref([]);
export const newCategory = ref({
  lang: "",
  content: "",
});
export const submitBtnDisabled = ref(true);
export const selectLang = ref("zh_tw");
export const selectValue = ref({
  lang: selectLang.value,
  enable: true,
  published: true,
});
export const articlePage = ref(1);

export const disabledTableData = reactive({
  data: [],
  counts: 0,
});

export const dataSwitch = ref({
  tag: false,
  anomaly: false,
  evaluation: false,
});
