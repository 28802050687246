import apiUrl from "../../api_url.global";
import {
  anomalyData,
  selectedModelName,
  dbNum,
  token,
} from "@/composition/store";
import { useInitSciChart } from "@/composition/index";
import {
  apiAnomalyModels,
  apiEvaluation,
  apiUpdateReport,
  apiDeleteReport,
  apiDeleteSavedAnomaly,
  apiOnlyEvaluation,
  // apiGetAnomalyUrl,
} from "@/composition/api";
import axios from "axios";

export const evaluationModeFn = () => {
  const { initSciChart } = useInitSciChart();
  // const getAnomalyUrl = async (selectedModelName) => {
  //   const config: any = {
  //     baseURL: `${apiUrl.url}${dbNum.value}`,
  //     url: `models/details/${selectedModelName}`,
  //     headers: {
  //       method: "get",
  //     },
  //   };
  //   await apiGetAnomalyUrl(config);
  // };
  const getAnomalyModels = async () => {
    const config: any = {
      baseURL: `${apiUrl.url}${dbNum.value}`,
      url: "/models/name",
      headers: {
        "Content-Type": "application/json",
        platform: "web",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        method: "get",
        data: {},
      },
    };
    await apiAnomalyModels(config);
  };
  const getAnomalyUrl = async (selectedModelName) => {
    // console.log("現在的model name=>", selectedModelName, dbNum.value);
    let url: string = "";

    await axios
      .get(`${apiUrl.url}${dbNum.value}/models/details/${selectedModelName}`)
      .then((res) => {
        url = res.data.url;
      });
    return url;
  };

  const activeEvaluationMode = async (val, id) => {
    // await getAnomalyUrl(val);
    // let url: string = ((await getAnomalyUrl(val)) as unknown) as string;
    const config: any = {
      url: await getAnomalyUrl(val),
      // url: url,
      method: "options",
    };
    console.log(config);
    // if (val === "model_b1") {
    //   config.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/incare-autoencoder-ml-2";
    //   config.method = "options";
    // } else if (val === "model_b1_009") {
    //   config.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/incare-autoencoder-ml-2";
    //   config.method = "options";
    // } else if (val === "model_b2" || val === "model_b2_05") {
    //   config.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/incare-autoencoder-ml";
    //   config.method = "options";
    // } else if (val === "model_b_vpc_mi") {
    //   config.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/incare-autoencoder-ml-6";
    //   config.method = "options";
    // } else if (val === "v1.0.1") {
    //   config.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/incare-autoencoder-ml-7";
    //   config.method = "options";
    // } else if (val === "model_b3") {
    //   config.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/incare-autoencoder-ml-8";
    //   config.method = "options";
    // } else if (val === "v1.0.2") {
    //   config.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/v1-0-2";
    //   config.method = "options";
    // } else if (val === "model_a1") {
    //   config.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/model_a1";
    //   config.method = "options";
    // } else if (val === "model_a1_v1") {
    //   config.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/model_a1_v1";
    //   config.method = "options";
    // } else if (val === "v1.0.3") {
    //   config.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/v1-0-3";
    //   config.method = "options";
    // } else if (val === "model_std_a1") {
    //   config.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/model_std_a1";
    //   config.method = "options";
    // } else if (val === "model_ste_a1") {
    //   config.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/model_ste_a1";
    //   config.method = "options";
    // } else if (val === "model_vpc_a1") {
    //   config.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/model_vpc_a1";
    //   config.method = "options";
    // } else if (val === "model_ensemble_a1") {
    //   config.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/model_ensemble_a1";
    //   config.method = "options";
    // } else if (val === "model_blw") {
    //   config.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/model_blw";
    //   config.method = "options";
    // } else if (val === "v1.0.4") {
    //   config.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/v1-0-4";
    //   config.method = "options";
    // } else if (val === "model_unknown") {
    //   config.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/model_unknown";
    //   config.method = "options";
    // } else if (val === "model_b3_v2") {
    //   config.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/model_b3-v2";
    //   config.method = "options";
    // } else {
    //   config.baseURL = apiUrl.url + dbNum.value;
    //   config.url = `/anomaly/${val}/${id}`;
    //   config.method = "get";
    //   config.data = {};
    //   config.headers = {
    //     "Content-Type": "application/json",
    //     platform: "web",
    //   };
    // }

    const config1: any = {
      url: await getAnomalyUrl(val),
      method: "post",
      data: {
        diagnosis_id: id,
        env: process.env.VUE_APP_URL,
        version: dbNum.value === "v2" ? "2" : "1",
      },
    };
    // if (val === "model_b1") {
    //   config1.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/incare-autoencoder-ml-2";
    //   config1.method = "post";
    //   config1.data = {
    //     diagnosis_id: id,
    //     env: process.env.VUE_APP_URL,
    //     // env: "dev",
    //     // env: "prod",
    //     version: "2",
    //   };
    // } else if (val === "model_b1_009") {
    //   config1.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/incare-autoencoder-ml-2";
    //   config1.method = "post";
    //   config1.data = {
    //     diagnosis_id: id,
    //     env: process.env.VUE_APP_URL,
    //     // env: "dev",
    //     // env: "prod",
    //     version: dbNum.value === "v2" ? "2" : "1",
    //   };
    // } else if (val === "model_b2" || val === "model_b2_05") {
    //   config1.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/incare-autoencoder-ml";
    //   config1.method = "post";
    //   config1.data = {
    //     diagnosis_id: id,
    //     env: process.env.VUE_APP_URL,
    //     // env: "dev",
    //     // env: "prod",
    //     version: dbNum.value === "v2" ? "2" : "1",
    //   };
    // } else if (val === "model_b_vpc_mi") {
    //   config1.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/incare-autoencoder-ml-6";
    //   config1.method = "post";
    //   config1.data = {
    //     diagnosis_id: id,
    //     env: process.env.VUE_APP_URL,
    //     // env: "dev",
    //     // env: "prod",
    //     version: dbNum.value === "v2" ? "2" : "1",
    //   };
    // } else if (val === "v1.0.1") {
    //   config1.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/incare-autoencoder-ml-7";
    //   config1.method = "post";
    //   config1.data = {
    //     diagnosis_id: id,
    //     env: process.env.VUE_APP_URL,
    //     // env: "dev",
    //     // env: "prod",
    //     version: dbNum.value === "v2" ? "2" : "1",
    //   };
    // } else if (val === "model_b3") {
    //   config1.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/incare-autoencoder-ml-8";
    //   config1.method = "post";
    //   config1.data = {
    //     diagnosis_id: id,
    //     env: process.env.VUE_APP_URL,
    //     // env: "dev",
    //     // env: "prod",
    //     version: dbNum.value === "v2" ? "2" : "1",
    //   };
    // } else if (val === "v1.0.2") {
    //   config1.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/v1-0-2";
    //   config1.method = "post";
    //   config1.data = {
    //     diagnosis_id: id,
    //     env: process.env.VUE_APP_URL,
    //     // env: "dev",
    //     // env: "prod",
    //     version: dbNum.value === "v2" ? "2" : "1",
    //   };
    // } else if (val === "model_a1") {
    //   config1.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/model_a1";
    //   config1.method = "post";
    //   config1.data = {
    //     diagnosis_id: id,
    //     env: process.env.VUE_APP_URL,
    //     // env: "dev",
    //     // env: "prod",
    //     version: dbNum.value === "v2" ? "2" : "1",
    //   };
    // } else if (val === "model_a1_v1") {
    //   config1.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/model_a1_v1";
    //   config1.method = "post";
    //   config1.data = {
    //     diagnosis_id: id,
    //     env: process.env.VUE_APP_URL,
    //     // env: "dev",
    //     // env: "prod",
    //     version: dbNum.value === "v2" ? "2" : "1",
    //   };
    // } else if (val === "v1.0.3") {
    //   config1.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/v1-0-3";
    //   config1.method = "post";
    //   config1.data = {
    //     diagnosis_id: id,
    //     env: process.env.VUE_APP_URL,
    //     // env: "dev",
    //     // env: "prod",
    //     version: dbNum.value === "v2" ? "2" : "1",
    //   };
    // } else if (val === "model_std_a1") {
    //   config1.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/model_std_a1";
    //   config1.method = "post";
    //   config1.data = {
    //     diagnosis_id: id,
    //     env: process.env.VUE_APP_URL,
    //     // env: "dev",
    //     // env: "prod",
    //     version: dbNum.value === "v2" ? "2" : "1",
    //   };
    // } else if (val === "model_ste_a1") {
    //   config1.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/model_ste_a1";
    //   config1.method = "post";
    //   config1.data = {
    //     diagnosis_id: id,
    //     env: process.env.VUE_APP_URL,
    //     // env: "dev",
    //     // env: "prod",
    //     version: dbNum.value === "v2" ? "2" : "1",
    //   };
    // } else if (val === "model_vpc_a1") {
    //   config1.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/model_vpc_a1";
    //   config1.method = "post";
    //   config1.data = {
    //     diagnosis_id: id,
    //     env: process.env.VUE_APP_URL,
    //     // env: "dev",
    //     // env: "prod",
    //     version: dbNum.value === "v2" ? "2" : "1",
    //   };
    // } else if (val === "model_ensemble_a1") {
    //   config1.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/model_ensemble_a1";
    //   config1.method = "post";
    //   config1.data = {
    //     diagnosis_id: id,
    //     env: process.env.VUE_APP_URL,
    //     // env: "dev",
    //     // env: "prod",
    //     version: dbNum.value === "v2" ? "2" : "1",
    //   };
    // } else if (val === "model_blw") {
    //   config1.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/model_blw";
    //   config1.method = "post";
    //   config1.data = {
    //     diagnosis_id: id,
    //     env: process.env.VUE_APP_URL,
    //     // env: "dev",
    //     // env: "prod",
    //     version: dbNum.value === "v2" ? "2" : "1",
    //   };
    // } else if (val === "v1.0.4") {
    //   config1.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/v1-0-4";
    //   config1.method = "post";
    //   config1.data = {
    //     diagnosis_id: id,
    //     env: process.env.VUE_APP_URL,
    //     // env: "dev",
    //     // env: "prod",
    //     version: dbNum.value === "v2" ? "2" : "1",
    //   };
    // } else if (val === "model_unknown") {
    //   config1.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/model_unknown";
    //   config1.method = "post";
    //   config1.data = {
    //     diagnosis_id: id,
    //     env: process.env.VUE_APP_URL,
    //     // env: "dev",
    //     // env: "prod",
    //     version: dbNum.value === "v2" ? "2" : "1",
    //   };
    // } else if (val === "model_b3_v2") {
    //   config1.url =
    //     "https://us-central1-feisty-ceiling-251011.cloudfunctions.net/model_b3-v2";
    //   config1.method = "post";
    //   config1.data = {
    //     diagnosis_id: id,
    //     env: process.env.VUE_APP_URL,
    //     version: dbNum.value === "v2" ? "2" : "1",
    //   };
    // } else {
    //   config1.baseURL = apiUrl.url + dbNum.value;
    //   config1.url = `/anomaly/${val}/${id}`;
    //   config1.method = "get";
    //   config1.data = {};
    //   config1.headers = {
    //     "Content-Type": "application/json",
    //     platform: "web",
    //   };
    // }

    console.log(config1);

    const config2: any = {
      baseURL: apiUrl.url + dbNum.value,
      url: `/evaluation/${id}`,
      headers: {
        "Content-Type": "application/json",
        platform: "web",
      },
      method: "get",
      data: {},
    };
    if (val === "") {
      alert("請先選擇一個 Model Name 喔");
    } else if (val !== "") {
      await apiEvaluation(config, config1, config2);
    } else {
      await apiEvaluation(config, config1, config2);
    }
  };

  const getOnlyEvaluation = (id) => {
    const config: any = {
      baseURL: apiUrl.url + dbNum.value,
      url: `/evaluation/${id}`,
      headers: {
        "Content-Type": "application/json",
        platform: "web",
      },
      method: "get",
      data: {},
    };
    apiOnlyEvaluation(config);
  };
  const updateReport = async (id, lang = navigator.language) => {
    console.log(id, lang);
    const config: any = {
      baseURL: `${apiUrl.url}${dbNum.value}`,
      url: "/prediction/comment/get/comments/offline",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        platform: "web",
      },
      method: "post",
      data: {
        diagnosis_id: id,
        // lang: navigator.language
        // 	.toLowerCase()
        // 	.replace('-', '_'),
        // lang:
        // 	lang.value
        // 		.toLowerCase()
        // 		.replace('-', '_') ||
        // 	navigator.language
        // 		.toLowerCase()
        // 		.replace('-', '_')
        lang: lang.toLowerCase().replace("-", "_"),
        // ||
        // navigator.language
        // 	.toLowerCase()
        // 	.replace('-', '_')
      },
    };
    console.log(config);
    await apiUpdateReport(config);
  };
  const deleteReport = async (id) => {
    console.log(id);
    const config1: any = {
      baseURL: `${apiUrl.url}${dbNum.value}`,
      url: `/prediction/${id}/delete`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
      method: "patch",
    };
    const config2: any = {
      baseURL: `${apiUrl.url}${dbNum.value}`,
      url: `/models/delete/ml_result/${id}/${selectedModelName.value}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
      method: "delete",
    };
    // console.log(
    // 	selectedModelName.value
    // );

    console.log(config1);
    console.log(config2);

    await apiDeleteReport(config1, config2);
    // await apiDeleteSavedAnomaly(
    // 	config2
    // );
  };
  return {
    getAnomalyModels,
    activeEvaluationMode,
    selectedModelName,
    anomalyData,
    initSciChart,
    updateReport,
    deleteReport,
    getOnlyEvaluation,
    // getAnomalyUrl,
  };
};
