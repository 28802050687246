<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">
        <img
          src="@/assets/images/inCare-inline-orange.png"
          alt=""
          width="150"
          class="d-inline-block align-top"
        />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mb-2 mb-lg-0 me-auto">
          <li class="nav-item" v-if="diagnosesNameShow">
            <a
              class="nav-link"
              href="#"
              @click.prevent="changePage(`${dbNum}/diagnoses`)"
              >{{ t("header-nav.diagnoses") }}</a
            >
            <!-- <a class="nav-link" href="#" @click.prevent="changePage(`${dbNum}/diagnoses`)"
              >Diagnoses</a
            > -->
          </li>
          <li class="nav-item" v-if="calendarNameShow">
            <a
              href="#"
              class="nav-link"
              @click.prevent="changePage(`${dbNum}/calendar`)"
              >{{ t("header-nav.calendar") }}</a
            >
            <!-- <a href="#" class="nav-link" @click.prevent="changePage(`${dbNum}/calendar`)"
              >Calendar</a
            > -->
          </li>
          <li class="nav-item" v-if="adminNameShow">
            <a
              href="#"
              class="nav-link"
              @click.prevent="changePage(`${dbNum}/admin`)"
              >{{ t("header-nav.admin") }}</a
            >
            <!-- <a href="#" class="nav-link" @click.prevent="changePage(`${dbNum}/admin`)">
              Admin</a
            > -->
          </li>
          <li class="nav-item" v-if="adminNameShow">
            <a
              href="#"
              class="nav-link"
              @click.prevent="changePage(`${dbNum}/removed`)"
              >{{ t("header-nav.removed") }}</a
            >
            <!-- <a href="#" class="nav-link" @click.prevent="changePage(`${dbNum}/removed`)"
              >Removed</a
            > -->
          </li>
        </ul>
        <ul class="navbar-nav mb-2 mb-lg-0">
          <li class="nav-item">
            <select
              class="form-select-inline text-dark border-0 mt-2"
              aria-label="Default select example"
              v-model="locale"
              @change="updateReport(diagnoses.data[0].diagnosis_id, locale)"
            >
              <option value="" selected class="text-dark" disabled>
                Select Language
              </option>
              <option value="zh-TW" class="text-dark">繁體中文</option>
              <option value="zh-CN" class="text-dark">简体中文</option>
              <option value="en-US" class="text-dark">English</option>
            </select>
          </li>
          <li class="nav-item" v-if="!isLogin">
            <router-link class="nav-link" to="/v2">{{
              t("header-nav.login")
            }}</router-link>
            <!-- <router-link class="nav-link d-inline" to="/v2">Login</router-link> -->
          </li>
          <li class="nav-item" v-else>
            <a class="nav-link" href="#" @click.prevent="logout">{{
              t("header-nav.logout")
            }}</a>
            <!-- <a class="nav-link" href="#" @click.prevent="logout">Log Out</a> -->
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <router-view></router-view>
  <AutoLogout></AutoLogout>
  <footer class="mt-auto py-3 text-center">
    <img src="@/assets/images/intelliances-logo-noword.png" alt="" width="50" />
    Powered By Intelliances
  </footer>
</template>

<script lang="ts">
import AutoLogout from "./components/AutoLogout.vue";
import { onMounted, ref, computed, watch } from "vue";
import { useLoginFn } from "@/composition/index";
import { useEvaluationMode } from "@/composition/index";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import router from "./router/index";
import {
  dbNum,
  isLogin,
  userphone,
  diagnosesNameShow,
  calendarNameShow,
  adminNameShow,
  diagnoses,
} from "@/composition/store";
export default {
  components: { AutoLogout },
  setup() {
    const { updateReport } = useEvaluationMode();
    const { t, locale } = useI18n();
    console.log(locale.value);
    const lang = ref(locale.value);
    console.log(lang.value);
    //  const lang = ref(navigator.language);

    watch(locale, (newlocale) => {
      localStorage.setItem("locale", newlocale);
      console.log(locale.value);
    });
    const route = useRoute();

    const { logout } = useLoginFn();
    const changePage = (to) => {
      router.push(`/${to}`);
    };
    computed(() => {
      userphone.value = JSON.parse(localStorage.getItem("userinfo")).phone;
      isLogin.value = JSON.parse(localStorage.getItem("isLogin"));
      dbNum.value = localStorage.getItem("dbNum");
    });
    onMounted(() => {
      diagnosesNameShow.value = JSON.parse(
        localStorage.getItem("navBarShow")
      ).diagnosesNameShow;
      calendarNameShow.value = JSON.parse(
        localStorage.getItem("navBarShow")
      ).calendarNameShow;
      adminNameShow.value = JSON.parse(
        localStorage.getItem("navBarShow")
      ).adminNameShow;
    });
    const test = (value) => {
      console.log(value);
    };
    return {
      test,
      changePage,
      logout,
      isLogin,
      dbNum,
      userphone,
      diagnosesNameShow,
      calendarNameShow,
      adminNameShow,
      t,
      locale,
      updateReport,
      diagnoses,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  text-align: center;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
