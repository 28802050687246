<template>
  <div id="notPage">
    <h1 id="notTitle">404</h1>
  </div>
</template>

<script>
export default {
  setup() {
    return;
  }
};
</script>

<style>
#notPage {
  width: 100%;
  height: 500px;
  background: green;
}
#notTitle {
  font-size: 60px;
  color: tomato;
}
</style>
