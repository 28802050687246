<template></template>
<script>
import { onMounted, onUnmounted } from "vue";
import { events, warningTimer, logoutTimer, isLogin } from "@/composition/store";
import { useLoginFn } from "@/composition/index";
export default {
  setup() {
    const { logout } = useLoginFn();
    onUnmounted(() => {
      events.value.forEach((e) => {
        window.removeEventListener(e, resetTimers);
        // console.log(e);
      });
      resetTimers();
    });
    onMounted(() => {
      // console.log(events.value);
      window.addEventListener("blur", () => {
        clearTimeout(warningTimer.value);
        clearTimeout(logoutTimer.value);
      });
      events.value.forEach((e) => {
        window.addEventListener(e, resetTimers);
        // console.log(e);
      });
      // window.addeventListener("blur", console.log("blur"));
      setTimers();
    });
    // const blur = () => {
    //   window.addeventListener("blur", console.log("blur"));
    // };
    const setTimers = () => {
      // products
      // warningTimer.value = setTimeout(warningMessage, 20 * 60 * 1000);
      // logoutTimer.value = setTimeout(logout, 20.5 * 60 * 1000);
      // warningTimer.value = setTimeout(warningMessage, 30 * 60 * 1000);
      // if (isLogin.value === true) {
      //   logoutTimer.value = setTimeout(logout, 30.5 * 60 * 1000);
      // } else {
      //   logoutTimer.value === null;
      // }
      // // Demo
      // console.log(isLogin.value);
      // warningTimer.value = setTimeout(warningMessage, 0.25 * 60 * 1000);
      // if (isLogin.value === true) {
      //   logoutTimer.value = setTimeout(logout, 0.5 * 60 * 1000);
      // } else {
      //   logoutTimer.value === null;
      // }
      // localstorage 版本
      // localStorage.setItem("warningTimer", setTimeout(warningMessage, 0.25 * 60 * 1000));
      // if (isLogin.value === true) {
      //   localStorage.setItem("logoutTimer", setTimeout(logout, 0.5 * 60 * 1000));
      // } else {
      //   localStorage.setItem("logoutTimer", null);
      // }
      // localStorage + new Date() 版本
      // localStorage.setItem("timer", Date.now() + 30 * 60 * 1000 - Date.now());
      // localStorage.setItem("currentTime", Date.now());
      // localStorage.setItem("waringTargetTime", Date.now() + 0.5 * 60 * 1000);
      // localStorage.setItem("logoutTargetTime", Date.now() + 1 * 60 * 1000);
      // console.log(
      //   localStorage.getItem("waringTargetTime") - localStorage.getItem("currentTime")
      // );
      // setInterval(console.log("hello"), 1000);
      // localStorage.setItem(
      //   "warningTimer",
      //   setTimeout(
      //     warningMessage,
      //     localStorage.getItem("waringTargetTime") - localStorage.getItem("currentTime")
      //   )
      // );
      // if (isLogin.value === true) {
      //   localStorage.setItem(
      //     "logoutTimer",
      //     setTimeout(
      //       logout,
      //       localStorage.getItem("logoutTargetTime") - localStorage.getItem("currentTime")
      //     )
      //   );
      // } else {
      //   localStorage.setItem("logoutTimer", null);
      // }
      // warningTimer.value = setTimeout(
      //   warningMessage,
      //   localStorage.getItem("warningTargetTimer") - localStorage.getItem("currentTime")
      // );
      // if (isLogin.value === true) {
      //   logoutTimer.value = setTimeout(
      //     logout,
      //     localStorage.getItem("logoutTargetTimer") - localStorage.getItem("currentTime")
      //   );
      // } else {
      //   logoutTimer.value = null;
      // }
      // localStorage.setItem("warningTimer", setTimeout(warningMessage, timer + 10000))
    };
    const warningMessage = () => {
      if (isLogin.value === true) {
        alert(
          "因您已逾 5 分鐘無操作系統，為了保障您個人資料安全，系統將於 30 秒後將您自動登出，若想維持登入狀態，請按『確定』按鈕"
        );
        console.log("warning");
      }
    };
    // const logoutMessage = () => {
    //   alert("logout");
    //   console.log("logout");
    // };
    // const logoutUser = () => {
    //   document.getElementById("logout").submit;
    // };
    const resetTimers = () => {
      clearTimeout(warningTimer.value);
      clearTimeout(logoutTimer.value);
      // clearTimeout(localStorage.getItem("warningTimer"));
      // clearTimeout(localStorage.getItem("logoutTimer"));
      // console.log("warningTimer=>", localStorage.getItem("warningTimer"));
      // console.log("logoutTimer=>", localStorage.getItem("logoutTimer"));
      setTimers();
    };
    return { warningTimer, logoutTimer };
  }
};
</script>
