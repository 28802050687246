import { loginFn } from "@/composition/login";
export const useLoginFn = loginFn;

import { requestDiagnosesFn } from "@/composition/requestDiagnoses";
export const useRequestDiagnoses = requestDiagnosesFn;

import { modalFn } from "@/composition/modal";
export const useModalFn = modalFn;

import { evaluationModeFn } from "@/composition/evaluationMode";
export const useEvaluationMode = evaluationModeFn;

import { showECGChartFn } from "@/composition/showECGChart";
export const useShowECGChart = showECGChartFn;

import { initSciChartFn } from "@/composition/sciChart/initSciChart";
export const useInitSciChart = initSciChartFn;

import { requestAdminTableFn } from "@/composition/admin";
export const useRequestAdminTable = requestAdminTableFn;

import { requestCalendarTableFn } from "./requestCalendarTable";
export const useRequestCalendarTable = requestCalendarTableFn;

// import { editorfn } from "./editor";
// export const useEditorFn = editorfn;
