// import { useI18n } from 'vue-i18n';
import { createI18n } from 'vue-i18n/index';
// import locales from './language';
import tw from '@/language/zh-TW.json';
import cn from '@/language/zh-CN.json';
import en from '@/language/en-US.json';

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
	// console.log('loadLocaleMessages fun');
	const locales = require.context(
		'./locales',
		true,
		/[A-Za-z0-9-_,\s]+\.json$/i
	);
	const messages = {};
	locales.keys().forEach((key) => {
		const matched = key.match(
			/([A-Za-z0-9-_]+)\./i
		);
		if (matched && matched.length > 1) {
			const locale = matched[1];
			messages[locale] = locales(
				key
			).default;
		}
	});
	console.log(locales);
	console.log(messages);
	return messages;
}

// const messageData = {
// 	'en-US': en,
// 	'zh-TW': tw,
// 	'zh-CN': cn
// };
const locale = () => {
	if (navigator.language === 'zh-CN') {
		return 'zh-CN';
	} else if (
		navigator.language === 'zh-TW'
	) {
		return 'zh-TW';
	} else {
		return 'en-US';
	}
};

export default createI18n({
	legacy: false,
	// locale: navigator.language,
	locale: locale(),
	// locale:
	// localStorage.getItem('locale') ??
	// 	'en-us',
	fallbackLocale:
		process.env
			.VUE_APP_I18N_FALLBACK_LOCALE ||
		'en-US',
	// messages: loadLocaleMessages()
	messages: {
		'zh-TW': tw,
		'en-US': en,
		'zh-CN': cn
	}
});
// const i18n = createI18n({
// 	locale: 'ca',
// 	messages: {
// 		'zh-tw': tw,
// 		'en-us': en,
// 		'zh-cn': cn
// 	}
// });
// export default i18n;
