/* eslint-disable @typescript-eslint/camelcase */

interface Url {
	url: string;
}

const apiUrl: Url = {
	url: `https://${process.env.VUE_APP_URL}.intelliances.com/broker/medical/`
};

export default apiUrl;
