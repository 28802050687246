import axios from "axios";
import apiUrl from "../../api_url.global";
import {
  countNumber,
  dbNum,
  page,
  tableData,
  diagnoses,
  tagListData,
  selectTagData,
  anomalyData,
  evaluationData,
  modelName,
  isLogin,
  isLoading,
  diagnosesid,
  userphone,
  adminDataTable,
  languageList,
  attributes,
  diagnosesNameShow,
  calendarNameShow,
  adminNameShow,
  reportData,
  btnDisabled,
  articleList,
  tempArticleContent,
  imageList,
  categories,
  tempImgAry,
  submitBtnDisabled,
  selectLang,
  selectValue,
  disabledTableData,
  selectPhoneRemove,
  selectedModelName,
} from "@/composition/store";
import router from "@/router";
import {
  useInitSciChart,
  useRequestAdminTable,
  useRequestDiagnoses,
  useShowECGChart,
  useLoginFn,
} from "@/composition/index";
import { editorFn, getCategoriesList } from "@/composition/editor";

// 使用者登入
export const apiUserLogin = (config) => {
  isLoading.value = true;
  axios(config)
    .then((res) => {
      isLoading.value = false;
      console.log(res);
      const { role, user_id, username, phone, access_token } = res.data;
      localStorage.setItem(
        "userinfo",
        JSON.stringify({
          role: role,
          user_id: user_id,
          username: username,
          phone: phone,
        })
      );
      localStorage.setItem("access_token", access_token);
      localStorage.setItem(
        "searchinfo",
        JSON.stringify({
          user_id: user_id,
          phone: phone,
          access_token: access_token,
        })
      );

      // if (
      // 	dbNum.value === "v1" &&
      // 	isLogin.value === true
      // ) {
      // 	diagnosesNameShow.value ===
      // 		true;
      // 	calendarNameShow.value ===
      // 		false;
      // 	adminNameShow.value === false;
      // } else if (
      // 	dbNum.value === "v2" &&
      // 	isLogin.value === true
      // ) {
      // 	if (
      // 		phone.value === "77311699"
      // 	) {
      // 		diagnosesNameShow.value ===
      // 			true;
      // 		adminNameShow.value === true;
      // 		calendarNameShow.value ===
      // 			false;
      // 	} else if (
      // 		phone.value !== "77311699"
      // 	) {
      // 		diagnosesNameShow.value ===
      // 			false;
      // 		adminNameShow.value === false;
      // 		calendarNameShow.value = true;
      // 	}
      // } else {
      // 	diagnosesNameShow.value ===
      // 		false;
      // 	adminNameShow.value === false;
      // 	calendarNameShow.value = false;
      // }

      if (
        // phone === "77311699" ||
        dbNum.value === "v1"
      ) {
        diagnosesNameShow.value = true;
        adminNameShow.value = false;
        calendarNameShow.value = false;
        localStorage.setItem(
          "navBarShow",
          JSON.stringify({
            diagnosesNameShow: true,
            adminNameShow: false,
            calendarNameShow: false,
          })
        );
        router.push({
          path: `/${dbNum.value}/Diagnoses`,
        });
      } else if (role !== "admin" && dbNum.value === "v2") {
        diagnosesNameShow.value = false;
        adminNameShow.value = false;
        calendarNameShow.value = true;
        localStorage.setItem(
          "navBarShow",
          JSON.stringify({
            diagnosesNameShow: false,
            adminNameShow: false,
            calendarNameShow: true,
          })
        );
        router.push({
          path: `/${dbNum.value}/calendar`,
        });
      } else if (role === "admin" && dbNum.value === "v2") {
        diagnosesNameShow.value = true;
        adminNameShow.value = true;
        calendarNameShow.value = false;
        localStorage.setItem(
          "navBarShow",
          JSON.stringify({
            diagnosesNameShow: true,
            adminNameShow: true,
            calendarNameShow: false,
          })
        );
        router.push({
          path: `/${dbNum.value}/Diagnoses`,
        });
      } else {
        diagnosesNameShow.value = true;
        adminNameShow.value = true;
        calendarNameShow.value = false;
        router.push({
          path: `/${dbNum.value}/Diagnoses`,
        });
      }

      // 	}
      // }, 1000);
      localStorage.setItem("isLogin", JSON.stringify(true));
      isLogin.value = true;
      userphone.value = JSON.parse(localStorage.getItem("userinfo")).phone;
      // if (phone === "77311699") {
      // 	// userphone.value = phone;
      // 	userphone.value = JSON.parse(
      // 		localStorage.getItem(
      // 			"userinfo"
      // 		)
      // 	).phone;
      // }
      // const diagnosesNameShow = ref(
      // 	false
      // );
      // const calendarNameShow = ref(
      // 	false
      // );
      // const adminNameShow = ref(false);
    })
    .catch((err) => {
      alert(
        `登入失敗，請確認帳號 ${config.data.identifier} 及密碼 ${config.data.password} 是否正確`
      );
      console.log(err);
    });
};

// 使用者登出

export const apiUserLogout = (config) => {
  const { removeLocalAndSession } = useLoginFn();
  axios(config)
    .then((res) => {
      console.log(res);
      removeLocalAndSession();
    })
    .catch((err) => {
      console.log(err);
    });
};

// 取得病例清單
export const apiRequestDiagnoses = (config) => {
  isLoading.value = true;
  console.log(config);
  const { removeLocalAndSession } = useLoginFn();
  axios(config)
    .then(async (res) => {
      isLoading.value = false;
      console.log(res);
      res.data.data.forEach((item, index) => {
        // if (dbNum.value === 'v1') {
        // 	item.create_at = new Date(
        // 		item.create_at
        // 	).toLocaleString();
        // } else if (
        // 	dbNum.value === 'v2'
        // ) {
        // 	item.create_date = new Date(
        // 		item.create_date
        // 	).toLocaleString();
        // }
        item.index = page.value.diagnoses * 10 - 9 + index;
        item.notes =
          item.notes.length !== 0
            ? item.notes
                .reduce((acc, current) => acc + ", " + current)
                .replace(/(\[|]|")*/g, "")
            : "尚未標記";
      });
      console.log(userphone.value);
      tableData.data.length = 0;
      tableData.data = res.data.data;
      console.log(tableData.data);
    })
    .catch((err) => {
      if (
        err.response.data.message === "Invalid JWT token" ||
        err.response.data.message === "Invalid access token"
      ) {
        removeLocalAndSession();
        // router.push({
        // 	path: `/${dbNum.value}`
        // });
      }
    });
};

// 取得總病例數量
export const apiDataCounts = (config) => {
  const { requestDiagnoses } = useRequestDiagnoses();
  console.log(config);
  axios(config)
    .then((res) => {
      console.log(res);
      countNumber.value = res.data.data.diagnosis_counts;
    })
    .catch((err) => {
      console.log(err);
    });
};

// 取得 Disabled 病例清單

export const apiRequestDisabled = (config) => {
  const {
    requestDiagnoses,
    dataCounts,
    requestDisableDiagnoses,
  } = useRequestDiagnoses();
  console.log(config);
  axios(config)
    .then((res) => {
      console.log(res);
      res.data.data.diagnoses.forEach((item, index) => {
        item.create_date = new Date(item.create_date).toLocaleString();
        item.index = page.value.remove * 10 - 9 + index;
      });
      disabledTableData.data = res.data.data.diagnoses;
      disabledTableData.counts = res.data.data.counts;
    })
    .catch((err) => {
      console.log(err);
    });
};

// enable or disabled 病例

export const apiIsEnableDiagnoses = (config) => {
  const {
    requestDiagnoses,
    dataCounts,
    requestDisableDiagnoses,
  } = useRequestDiagnoses();
  axios(config)
    .then((res) => {
      console.log(res);
      requestDisableDiagnoses(page.value.remove, selectPhoneRemove.value);
      requestDiagnoses(page.value.diagnoses);
      dataCounts();
    })
    .catch((err) => {
      console.log(err);
    });
};

// 刪除病歷資料

export const apiDeleteDiagnoses = (config) => {
  console.log(config);
  const { requestDisableDiagnoses } = useRequestDiagnoses();
  axios(config)
    .then((res) => {
      console.log(res);
      requestDisableDiagnoses(page.value.remove, selectPhoneRemove.value);
    })
    .catch((err) => {
      console.log(err);
    });
};

// 勾選是否為模擬器資料

export const apiSwitchSynthetic = (config) => {
  const { requestDiagnoses } = useRequestDiagnoses();
  const { showECGChart } = useShowECGChart();
  axios(config)
    .then((res) => {
      console.log(res);
      const { diagnosis_id } = res.data.data;
      requestDiagnoses(page.value);
      showECGChart(diagnosis_id);
    })
    .catch((err) => {
      console.log(err);
    });
};

// 顯示 ECG 線圖

export const apiEcgData = (config) => {
  return new Promise<any>((resolve, reject) => {
    isLoading.value = true;
    axios(config)
      .then((res) => {
        isLoading.value = false;
        console.log(res);
        if (dbNum.value === "v1") {
          res.data.data.create_at = new Date(
            res.data.data.create_at
          ).toLocaleString();
        } else if (dbNum.value === "v2") {
          res.data.data.create_date = new Date(
            res.data.data.create_date
          ).toLocaleString();
        }
        diagnoses.data = [res.data.data];
        tableData.data = [res.data.data];

        // tableData.data.push(
        // 	...res.data.data
        // );
        // diagnoses.data.push(
        // 	...res.data.data
        // );
        // tableData.data = [
        // 	res.data.data
        // ];

        resolve([diagnoses, tableData]);
      })
      .catch((err) => {
        alert("沒有資料喔");
        console.log(err);
      });
  });
};

// 顯示 tagNote 區塊

export const apiTagNoteData = (config) => {
  return new Promise<any>((resolve, reject) => {
    isLoading.value = true;
    axios(config)
      .then((res) => {
        isLoading.value = false;
        console.log(res);
        tagListData.data.length = 0;
        tagListData.data.push(...res.data.data);
        resolve(tagListData);
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

// 存取 tagNote 區塊

export const apiSaveData = (config) => {
  isLoading.value = true;
  axios(config)
    .then((res) => {
      isLoading.value = false;
      console.log(res);
      tagListData.data.push({
        id: res.data.data.datas[0].note_id,
        channel: config.data.channel,
        created_at: "",
        note: config.data.note,
        x1: config.data.x1,
        x2: config.data.x2,
        diagnosis_id: config.data.diagmosis_id,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const apiDeleteData = (config) => {
  return new Promise<void>((resolve, reject) => {
    isLoading.value = true;
    console.log(config);
    axios(config)
      .then((res) => {
        isLoading.value = false;
        console.log(res);
        console.log(selectTagData.data.index);
        resolve();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export const apiModifyData = (config) => {
  return new Promise((resolve, reject) => {
    isLoading.value = true;
    console.log("Hello 1");

    axios(config)
      .then((res) => {
        isLoading.value = false;
        console.log("Hello 2");

        console.log(res);
        tagListData.data[selectTagData.data[0].index].note = config.data.note;
        selectTagData.data[0].note = JSON.parse(config.data.note)[0];
        selectTagData.data[0].channel = config.data.channel;
        resolve(selectTagData);
      })
      .catch((err) => {
        isLoading.value = false;
        console.log("Hello 3");
        console.dir(err);
      });
  });
};
export const apiDeleteEvaluationData = (config) => {
  return new Promise((resolve, reject) => {
    isLoading.value = true;
    axios(config)
      .then((res) => {
        isLoading.value = false;
        console.log(res);
      })
      .catch((err) => {
        isLoading.value = false;
        console.log(err);
      });
  });
};

export const apiAnomalyModels = (config) => {
  return new Promise((resolve, reject) => {
    isLoading.value = true;
    modelName.length = 0;
    axios(config)
      .then((res) => {
        isLoading.value = false;
        console.log("new", res);
        modelName.push(res.data.default);
        modelName.push(...res.data.challengers);
        selectedModelName.value = res.data.default;
        console.log(selectedModelName.value);

        // modelName.push({
        // 	value: res.data.default,
        // 	label: res.data.default
        // });
        // res.data.challengers.forEach(
        // 	(item) => {
        // 		modelName.push({
        // 			value: item,
        // 			label: item
        // 		});
        // 	}
        // );
        console.log(modelName);

        // resolve(modelName);
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export const apiEvaluation = (config?, config1?, config2?) => {
  return new Promise((resolve, reject) => {
    const { initSciChart } = useInitSciChart();
    isLoading.value = true;
    const preCheck = () => {
      return axios(config);
    };

    const getAnomaly = () => {
      return axios(config1);
    };
    const getEvaluation = () => {
      return axios(config2);
    };
    axios
      .all([preCheck(), getAnomaly(), getEvaluation()])
      .then(
        axios.spread((precheck, anomaly, evaluation) => {
          isLoading.value = false;
          console.log("this is precheck=>", precheck);
          console.log("this is anomaly=>", anomaly);
          console.log(anomaly.data.data.interpretation);
          console.log("this is evaluation=>", evaluation);
          anomalyData.data.length = 0;
          anomalyData.data.push(anomaly.data.data);
          anomalyData.data.push(anomaly.data.data.interpretation);
          evaluationData.data.length = 0;
          evaluationData.data.push(...evaluation.data.data);
          initSciChart();
          // diagnosesid.value
        })
      )
      .catch((err) => {
        isLoading.value = false;
        alert("資料回傳失敗，請再按一次 Evaluation Mode 按鈕");
        console.log(err);
      });
    resolve([anomalyData, evaluationData]);
    // console.log(diagnosesid.value);
  });
};

export const apiOnlyEvaluation = (config) => {
  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        console.log(res);
        evaluationData.data.length = 0;
        evaluationData.data.push(...res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export const apiModifyEvaluation = (config) => {
  return new Promise((resolve, reject) => {
    isLoading.value = true;
    console.log(config);
    axios(config)
      .then((res) => {
        isLoading.value = false;
        console.log(res);
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export const apiGetUserIdFromPhoneNumber = (phone) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${apiUrl.url}${dbNum.value}/user/id/${phone}`
        // 'https://dev.intelliances.com/broker/medical/v2/user/id/' +
        // 	phone
      )
      .then((res) => {
        console.log(res);
        resolve(res.data.data);
      });
  });
};

export const apiAdminPageTable = (config) => {
  return new Promise((resolve, reject) => {
    console.log(config);
    axios(config).then((res) => {
      console.log(res);
      adminDataTable.value.length = 0;
      adminDataTable.value.push(...res.data);
      console.log(adminDataTable.value);
    });
  });
};
export const apiEditCommentName = (config) => {
  return new Promise<any>((resolve, reject) => {
    const { requestAdminTable } = useRequestAdminTable();
    axios(config).then((res) => {
      console.log(res);
      requestAdminTable();
    });
  });
};
export const apiLanguage = (config) => {
  return new Promise((resolve, reject) => {
    console.log(config);
    axios(config).then((res) => {
      console.log(res);
      languageList.value.length = 0;
      languageList.value.push(...res.data.value);
    });
  });
};
export const apiAddLanguage = (config) => {
  return new Promise((resolve, reject) => {
    const { requestLanguageList, requestAdminTable } = useRequestAdminTable();
    console.log(config);
    axios(config).then((res) => {
      console.log(res);
      requestLanguageList();
      requestAdminTable();
    });
  });
};

export const apiAddLanguageColumn = (config) => {
  return new Promise((resolve, reject) => {
    axios(config).then((res) => {
      console.log(res);
    });
  });
};

export const apiAddLanguage_2 = (config, config1) => {
  return new Promise<void>((resolve, reject) => {
    const addLanguageList = () => {
      return axios(config);
    };
    const addLanguageTable = () => {
      return axios(config1);
    };
    axios.all([addLanguageList(), addLanguageTable()]).then(
      axios.spread((list, table) => {
        console.log("this is list=>", list);
        console.log("this is table=>", table);
      })
    );
  });
};

export const apiDeleteLanguage = (config) => {
  return new Promise((resolve, reject) => {
    const { requestLanguageList, requestAdminTable } = useRequestAdminTable();
    console.log(config);
    axios(config).then((res) => {
      console.log(res);
      requestLanguageList();
      requestAdminTable();
    });
  });
};

export const apiAddComment = (config) => {
  return new Promise((resolve, reject) => {
    const { requestAdminTable } = useRequestAdminTable();
    console.log(config);
    axios(config).then((res) => {
      console.log(res);
      requestAdminTable();
      // adminDataTable.value.push({});
    });
  });
};
export const apiDeleteComment = (config) => {
  return new Promise((resolve, reject) => {
    const { requestAdminTable } = useRequestAdminTable();
    console.log(config);
    axios(config).then((res) => {
      console.log(res);
      requestAdminTable();
    });
  });
};
export const apiEditComment = (config) => {
  return new Promise((resolve, reject) => {
    const { requestAdminTable } = useRequestAdminTable();
    axios(config).then((res) => {
      console.log(res);
      requestAdminTable();
    });
  });
};
export const apiUpdateSuggestions = (config) => {
  return new Promise((resolve, reject) => {
    const { requestAdminTable } = useRequestAdminTable();
    axios(config).then((res) => {
      console.log(res);
      requestAdminTable();
    });
  });
};
export const apiRequestCalendarTable = (config) => {
  return new Promise((resolve, reject) => {
    console.log(config);
    axios(config)
      .then((res) => {
        console.log(res);

        res.data.data.forEach((item) => {
          console.log(item);
          item.create_date = new Date(item.create_date).toLocaleString();
          item.prediction_result =
            item.prediction_result.length !== 0
              ? item.prediction_result
                  .reduce((acc, current) => acc + ", " + current)
                  .replace(/(\[|]|")*/g, "")
              : "尚未標記";
        });
        tableData.data = res.data.data;
        console.log(tableData.data);
      })
      .catch((err) => {
        console.log(err);
      });
  });
};
export const apiRequestCalendarMark = (config) => {
  return new Promise((resolve, reject) => {
    axios(config).then((res) => {
      console.log(res);
      res.data.data.forEach((item) => {
        if (item.prediction_result[0] === "Normal") {
          attributes.value.push({
            highlight: {
              color: "blue",
              fillMode: "light",
            },
            dates: new Date(item.create_date),
            order: 1,
          });
        } else if (item.prediction_result.length === 0) {
          attributes.value.push({
            highlight: {
              color: "blue",
              fillMode: "outline",
            },
            dates: new Date(item.create_date),
            order: 0,
          });
        } else if (item.prediction_result[0] !== "Normal") {
          attributes.value.push({
            highlight: {
              color: "red",
              fillMode: "light",
            },
            dates: new Date(item.create_date),
            order: 2,
          });
        }
      });
    });
  });
};
export const apiUpdateReport = (config) => {
  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        console.log(res);
        console.log(res.data[0].description[0].lang);

        if (res.data[0].message) {
          alert(
            res.data[0].message + ", please click Update Report Button later."
          );
          btnDisabled.value = false;
        } else {
          reportData.value.data = res.data;
          btnDisabled.value = true;
          // alert(
          // 	"Report updated successfully"
          // );
        }
      })
      .catch((err) => {
        alert(
          "The Report is still being generated, please click Update Report later"
        );
        console.log(err);
      });
  });
};
export const apiDeleteReport = (config1?, config2?) => {
  return new Promise((resolve, reject) => {
    const report = () => {
      return axios(config1);
    };
    const data = () => {
      return axios(config2);
    };
    axios.all([report(), data()]).then(
      axios.spread((report, data) => {
        console.log(report);
        console.log(data);
        reportData.value.data = report.data.data.prediction_result.length;
        btnDisabled.value = false;
        alert("報告已刪除");
      })
    );
    // axios(config)
    // 	.then((res) => {
    // 		console.log(res);

    // 		console.log(
    // 			res.data.data
    // 				.prediction_result.length
    // 		);
    // 		reportData.value.data =
    // 			res.data.data.prediction_result.length;
    // 		btnDisabled.value = false;
    // 		alert('報告已刪除');
    // 	})
    // 	.catch((err) => {
    // 		console.log(err);
    // 	});
  });
};
export const apiDeleteSavedAnomaly = (config) => {
  return new Promise((resolve, reject) => {
    console.log(config);

    axios(config)
      .then((res) => {
        console.log("succeed");
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export const apiGetArticle = (config) => {
  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        console.log(res);

        console.log(res.data.data);
        articleList.value.posts.length = 0;
        articleList.value.posts.push(...res.data.data.posts);
        articleList.value.counts = res.data.data.counts;
        console.log(articleList.value);
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export const apiCreateArticle = (config) => {
  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        console.log(res);
        editorFn(
          selectLang.value,
          selectValue.value.enable,
          selectValue.value.published,
          1
        );
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export const apiReleaseArticle = (config) => {
  return new Promise((resolve, reject) => {
    console.log(config);

    axios(config)
      .then((res) => {
        console.log(res);
        alert(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  });
};
export const apiGetSingleArticle = (config) => {
  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        console.log(res);
        const {
          _id,
          title,
          content,
          lang,
          category,
          cover_image,
          attachments,
        } = res.data.data;
        tempImgAry.value.length = 0;
        tempArticleContent.value.attachments.length = 0;
        tempArticleContent.value.post_id = _id;
        tempArticleContent.value.title = title;
        tempArticleContent.value.content = content;
        tempArticleContent.value.lang = lang;
        tempArticleContent.value.category = category;
        tempArticleContent.value.cover_image = cover_image;
        tempArticleContent.value.attachments.push(...attachments);
        attachments.forEach((item) => {
          if (item._id === cover_image) {
            tempArticleContent.value.cover_image_link = item.target_url;
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export const apiEditArticle = (config) => {
  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export const apiEnableArticle = (config) => {
  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        console.log(res);
        editorFn(
          selectLang.value,
          selectValue.value.enable,
          selectValue.value.published,
          1
        );
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export const apiDeleteArticle = (config) => {
  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        console.log(res);
        editorFn(
          selectLang.value,
          selectValue.value.enable,
          selectValue.value.published,
          1
        );
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export const apiGetImageList = (config) => {
  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        console.log(res);
        // resolve(res.data.data);
        imageList.value.push(...res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export const apiGetCategoriesList = (config) => {
  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        console.log(res);
        categories.value.length = 0;
        categories.value.push(...res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export const apiAddCategory = (config) => {
  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        console.log(res);
        getCategoriesList(selectLang.value);
      })
      .catch((err) => {
        console.log(err);
      });
  });
};
export const apiDeleteCategory = (config) => {
  return new Promise((resolve, reject) => {
    axios(config).then((res) => {
      console.log(res);
      getCategoriesList(selectLang.value);
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const apiEditCategory = (config) => {
  return new Promise((resolve, reject) => {
    axios(config).then((res) => {
      console.log(res);
      getCategoriesList(selectLang.value);
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const apiDeleteImage = (config) => {
  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        console.log(res);
        tempArticleContent.value.cover_image_link = "";
        tempArticleContent.value.cover_image = "";
      })
      .catch((err) => {
        console.log(err);
      });
  });
};
// export const apiGetAnomalyUrl = (config) => {
// 	return new Promise((resolve, reject) => {
// 		axios(config).then((res) => {
// 			resolve(res)
// 		}
// 	 ))
// };
export const apiGetAnomalyUrl = (config) => {
  return new Promise((resolve, reject) => {
    axios(config).then((res) => {
      console.log(res);
    });
  });
};
