import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import NotFoundPage from "../views/NotFoundPage.vue";
import DownloadApp from "../views/_downloadapp.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/:dbNum",
    name: "Login",
    component: () => import("../views/_Login.vue"),
    // import(
    // 	"../views/NotFoundPage.vue"
    // )
  },
  {
    path: "/:dbNum/Diagnoses",
    name: "Diagnoses",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Diagnoses/index.vue"),
  },
  {
    path: "/:dbNum/Diagnoses/:diagnosesid",
    name: "diagnosesChart",
    component: () => import("../views/Diagnoses/_diagnosesid.vue"),
  },
  {
    path: "/:dbNum/Calendar",
    name: "calendar",
    component: () => import("../views/Calendar/index.vue"),
  },
  {
    path: "/:dbNum/Admin",
    name: "admin",
    component: () => import("../views/Admin/index.vue"),
  },
  {
    path: "/:dbNum/Removed",
    name: "Removed",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Removed/index.vue"),
  },
  {
    path: "/:dbNum/downloadappv1",
    name: "downloadappv1",
    component: DownloadApp,
  },
  {
    path: "/:pathMatch(.*)",
    name: "NotFoundPage",
    component: NotFoundPage,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
