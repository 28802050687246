<template>
  <div class="container-fluid">
    <div class="container" v-if="isWeb === true">
      <div class="text-center p-3 alert alert-danger mt-6">
        {{ message }}
      </div>
    </div>
    <div v-else class="container">
      <div class="text-center p-3 alert alert-primary mt-6">
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { identifyDevice } from "@/composition/identifyDevice";

export default {
  setup() {
    const { message, isWeb, ua } = identifyDevice();
    return { message, isWeb, ua };
  },
};
</script>

<style scoped></style>
