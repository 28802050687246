import { ref } from "vue";

export const identifyDevice = () => {
  const ua = navigator.userAgent;
  // const ua =
  //   "Mozilla/5.0 (Linux; Android 11; M2012K11AI) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/87.0.4280.141 Mobile Safari/537.36 XiaoMi/MiuiBrowser/14.2.10";
  // const ua =
  //   "Mozilla/5.0 (Linux; Android 5.1.1; SM-T3777) AppleWebKit/537.36 (KHTML,likeGecko) Chrome/95.0.4638.74 Safari/537.36";

  const device = ref("");

  const isAndroid = ref(ua.indexOf("Android") > -1 || ua.indexOf("Adr") > -1);
  const isiOS = ref(!!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/));
  const isMobile = ref(isAndroid.value || isiOS.value);
  const isWeb = ref(!isMobile.value);
  const message = ref("");
  console.log(
    "Andriod=>" + isAndroid.value,
    "ios=>" + isiOS.value,
    "mobile=>" + isMobile.value,
    "web=>" + isWeb.value
  );

  if (isWeb.value) {
    device.value = "Web";
    message.value = "请使用移动设备开启页面";
    // const webVersion = /Mac OS X (\d+_\d+)/.exec(ua)[1].replace("_", ".");
    // console.log("Web 版本：", webVersion, typeof webVersion);
  } else if (isiOS.value) {
    device.value = "iOS";
    const iphoneVersion = /CPU iPhone OS (\d+(\.\d+)?)/
      .exec(ua)[1]
      .replace("_", ".");
    if (Number(iphoneVersion) >= 12) {
      message.value = `当前系统符合要求，请到 App Store 搜寻 “inCare” 后安装 app。`;
    } else {
      message.value = `當前系統版本不支持，請使用 iOS 12.0 以上系統`;
    }
  } else {
    device.value = "Android";
    const androidVersion = /Android (\d+(\.\d+)?)/
      .exec(ua)[1]
      .replace("_", ".");

    if (Number(androidVersion) >= 9) {
      message.value = `当前系统符合要求，请到应用商店搜寻 “inCare” 后安装 app。`;
    } else {
      message.value = `當前系統版本不支持，請使用 Android 9.0 以上系統`;
    }
  }
  return { message, isWeb, ua };
};
