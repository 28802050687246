<template>
  <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active"></div>
      <div class="carousel-item"></div>
      <div class="carousel-item"></div>
      <div class="carousel-item"></div>
      <div class="carousel-item"></div>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#carouselExampleControls"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#carouselExampleControls"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>
<style scoped>
.carousel-inner,
.carousel-item,
.carousel-item.active {
  min-height: 60vh;
}

.carousel-item:nth-child(1) {
  background-image: url("../../src/assets/images/inCare.176.png");
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center center;
}

.carousel-item:nth-child(2) {
  background-image: url("../../src/assets/images/inCare.177.png");
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center center;
}

.carousel-item:nth-child(3) {
  background-image: url("../../src/assets/images/inCare.178.png");
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center center;
}
.carousel-item:nth-child(4) {
  background-image: url("../../src/assets/images/inCare.179.png");
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center center;
}

.carousel-item:nth-child(5) {
  background-image: url("../../src/assets/images/inCare.180.png");
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center center;
}
@media (min-width: 1023px) {
  .carousel-inner,
  .carousel-item,
  .carousel-item.active {
    min-height: 60vh;
  }
  .carousel-item:nth-child(1),
  .carousel-item:nth-child(2),
  .carousel-item:nth-child(3),
  .carousel-item:nth-child(4),
  .carousel-item:nth-child(5) {
    background-size: 50%;
  }
}

@media (min-width: 768px) {
  .carousel-inner,
  .carousel-item,
  .carousel-item.active {
    min-height: 85vh;
  }
  .carousel-item:nth-child(1),
  .carousel-item:nth-child(2),
  .carousel-item:nth-child(3),
  .carousel-item:nth-child(4),
  .carousel-item:nth-child(5) {
    background-size: 50%;
  }
}
</style>
