
import AutoLogout from "./components/AutoLogout.vue";
import { onMounted, ref, computed, watch } from "vue";
import { useLoginFn } from "@/composition/index";
import { useEvaluationMode } from "@/composition/index";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import router from "./router/index";
import {
  dbNum,
  isLogin,
  userphone,
  diagnosesNameShow,
  calendarNameShow,
  adminNameShow,
  diagnoses,
} from "@/composition/store";
export default {
  components: { AutoLogout },
  setup() {
    const { updateReport } = useEvaluationMode();
    const { t, locale } = useI18n();
    console.log(locale.value);
    const lang = ref(locale.value);
    console.log(lang.value);
    //  const lang = ref(navigator.language);

    watch(locale, (newlocale) => {
      localStorage.setItem("locale", newlocale);
      console.log(locale.value);
    });
    const route = useRoute();

    const { logout } = useLoginFn();
    const changePage = (to) => {
      router.push(`/${to}`);
    };
    computed(() => {
      userphone.value = JSON.parse(localStorage.getItem("userinfo")).phone;
      isLogin.value = JSON.parse(localStorage.getItem("isLogin"));
      dbNum.value = localStorage.getItem("dbNum");
    });
    onMounted(() => {
      diagnosesNameShow.value = JSON.parse(
        localStorage.getItem("navBarShow")
      ).diagnosesNameShow;
      calendarNameShow.value = JSON.parse(
        localStorage.getItem("navBarShow")
      ).calendarNameShow;
      adminNameShow.value = JSON.parse(
        localStorage.getItem("navBarShow")
      ).adminNameShow;
    });
    const test = (value) => {
      console.log(value);
    };
    return {
      test,
      changePage,
      logout,
      isLogin,
      dbNum,
      userphone,
      diagnosesNameShow,
      calendarNameShow,
      adminNameShow,
      t,
      locale,
      updateReport,
      diagnoses,
    };
  },
};
