/* eslint-disable @typescript-eslint/camelcase */
import apiUrl from '../../api_url.global';
import {
	apiEcgData,
	apiTagNoteData
} from '@/composition/api';
import {
	token,
	dbNum
} from '@/composition/store';
import { useRequestDiagnoses } from '@/composition/index';

export const showECGChartFn = () => {
	const {
		searchPhoneData
	} = useRequestDiagnoses();
	const showECGChart = async (id) => {
		if (id) {
			console.log(id);
			const config: any = {
				baseURL: `${apiUrl.url}${dbNum.value}`,
				url: '/diagnoses/' + id,
				headers: {
					'Content-Type':
						'application/json',
					platform: 'web'
				},
				method: 'get',
				data: {}
			};
			await apiEcgData(config);
		} else {
			searchPhoneData();
		}
	};

	const showTagRange = async (id) => {
		const config: any = {
			baseURL: `${apiUrl.url}${dbNum.value}`,
			url: '/notes/' + id,
			headers: {
				'Content-Type':
					'application/json',
				platform: 'web',
				Authorization:
					'Bearer ' +
					localStorage.getItem(
						'access_token'
					)
			},
			method: 'get',
			data: {}
		};
		await apiTagNoteData(config);
	};
	return {
		showECGChart,
		showTagRange
	};
};
