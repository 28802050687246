import apiUrl from "../../api_url.global";
import { ref } from "vue";
import {
  dbNum,
  tempArticleContent,
  tempImgAry,
  imgAry,
  deleteImgAry,
  submitBtnDisabled,
  selectValue,
  articlePage,
} from "@/composition/store";
import {
  apiCreateArticle,
  apiGetArticle,
  apiReleaseArticle,
  apiGetSingleArticle,
  apiEnableArticle,
  apiGetImageList,
  apiGetCategoriesList,
  apiAddCategory,
  apiDeleteCategory,
  apiEditCategory,
  apiDeleteImage,
  apiEditArticle,
  apiDeleteArticle,
} from "@/composition/api";
import axios from "axios";

export const editorFn = async (lang, enable, published, page) => {
  console.log(lang, enable, published, page);
  if (page === 1) {
    articlePage.value = page;
  }
  selectValue.value.lang = lang;
  selectValue.value.enable = enable;
  selectValue.value.published = published;
  const config: any = {
    baseURL: `${apiUrl.url}${dbNum.value}`,
    url: `/post/find/list`,
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data: {
      user_id: JSON.parse(localStorage.getItem("userinfo")).user_id,
      lang: lang,
      enable: enable,
      published_flag: published,
      start_num: page * 10 - 9,
      end_num: page * 10,
      order_by: "desc",
    },
  };
  console.log(config);
  apiGetArticle(config);
};

export const createArticle = async (content) => {
  const config: any = {
    baseURL: `${apiUrl.url}${dbNum.value}`,
    url: `/post/create`,
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data: { ...content },
    // data: {
    // 	title:"標題",
    // 	category: "ECG",
    // 	content: "xxxx",
    // 	lang: "zh_tw",
    // 	cover_image:
    // 		"6278c6fad178bc00433c2f59",
    // 	attachments: [
    // 		"6278c6fad178bc00433c2f59"
    // 	],
    // 	create_user:
    // 		"180f891d-2dda-4fa1-8280-35de6cbd0e0b"
    // }
  };
  console.log(config);
  apiCreateArticle(config);
};
// export const editArticle = async()=>{}

export const handleSuccess = async (data) => {
  console.log(data);

  const formData = new FormData();
  formData.append("file", data);
  formData.append(
    "user_id",
    JSON.parse(localStorage.getItem("userinfo")).user_id
  );
  const config: any = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  console.log(formData);

  await axios
    .post(`${apiUrl.url}${dbNum.value}/gcs/upload`, formData, config)
    .then((res) => {
      console.log(res);
      tempArticleContent.value.cover_image_link = res.data.data.target_url;
      tempArticleContent.value.cover_image = res.data.data._id;
      // tempArticleContent.value.attachments.push(
      // 	res.data.data._id
      // );
      tempImgAry.value.push(res.data.data);
      submitBtnDisabled.value = false;
      console.log(tempArticleContent.value);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteImage = () => {
  // const config: any = {
  // 	url: `${apiUrl.url}${dbNum.value}/gcs/delete`,
  // 	headers: {
  // 		"Content-Type": "application/json"
  // 	},
  // 	method: "delete",
  // 	data: {
  // 		user_id:
  // 			"180f891d-2dda-4fa1-8280-35de6cbd0e0b",
  // 		file_ids: [data]
  // 	}
  // };
  tempArticleContent.value.cover_image_link = "";
  tempArticleContent.value.cover_image = "";
  // const idx = tempArticleContent.value.attachments.indexOf(
  // 	data
  // );
  // tempArticleContent.value.attachments.splice(
  // 	idx
  // );
  // console.log(config);
  // console.log(idx);
  console.log(tempArticleContent.value);
  submitBtnDisabled.value = true;

  // apiDeleteImage(config);
};

export const uploadFile = async (data) => {
  const formData = new FormData();
  formData.append("file", data);
  formData.append(
    "user_id",
    JSON.parse(localStorage.getItem("userinfo")).user_id
  );
  const config: any = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const imgLink = await axios
    .post(`${apiUrl.url}${dbNum.value}/gcs/upload`, formData, config)
    .then((res) => {
      tempImgAry.value.push(res.data.data);
      console.log(res.data.data);
      return res.data.data.target_url;
    })
    .catch((err) => {
      console.log(err);
    });
  return imgLink;
};

export const textChange = (editor) => {
  console.log(editor.getHTML());
  tempArticleContent.value.create_user = JSON.parse(
    localStorage.getItem("userinfo")
  ).user_id;
  tempArticleContent.value.content = editor.getHTML();
  tempImgAry.value.forEach((item) => {
    console.log(item);

    if (editor.getHTML().includes(item.target_url)) {
      // imgAry.value.push(item._id);
      console.log(item._id);
      tempArticleContent.value.attachments.push(item._id);
    } else if (item._id === tempArticleContent.value.cover_image) {
      tempArticleContent.value.attachments.push(item._id);
    } else {
      deleteImgAry.value.push(item._id);
    }
  });
  console.log(imgAry.value);
  console.log(deleteImgAry.value);

  if (deleteImgAry.value.length !== 0) {
    const config: any = {
      url: `${apiUrl.url}${dbNum.value}/gcs/delete`,
      headers: {
        "Content-Type": "application/json",
      },
      method: "delete",
      data: {
        user_id: JSON.parse(localStorage.getItem("userinfo")).user_id,
        file_ids: [...deleteImgAry.value],
      },
    };
    console.log(config);
    axios(config)
      .then(async (res) => {
        await console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log();
  }
  createArticle(tempArticleContent.value);
  console.log(tempArticleContent.value);

  objectClean(editor);
};
export const cancelChange = (editor) => {
  const deleteImgList = [];
  tempImgAry.value.forEach((item) => {
    deleteImgList.push(item._id);
  });
  const config: any = {
    url: `${apiUrl.url}${dbNum.value}/gcs/delete`,
    headers: {
      "Content-Type": "application/json",
    },
    method: "delete",
    data: {
      user_id: JSON.parse(localStorage.getItem("userinfo")).user_id,
      file_ids: [...deleteImgList],
    },
  };
  apiDeleteImage(config);
  objectClean(editor);
  // console.log(config);
};

export const releaseArticle = async (id, time) => {
  const config: any = {
    baseURL: `${apiUrl.url}${dbNum.value}`,
    url: `/post/schedule`,
    headers: {
      "Content-Type": "application/json",
    },
    method: "patch",
    data: {
      user_id: JSON.parse(localStorage.getItem("userinfo")).user_id,
      post_id: id,
      published_time: time,
    },
  };
  apiReleaseArticle(config);
};

export const getSingleArticle = (id) => {
  const config: any = {
    baseURL: `${apiUrl.url}${dbNum.value}`,
    url: `/post/${JSON.parse(localStorage.getItem("userinfo")).user_id}/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
    method: "get",
  };

  apiGetSingleArticle(config);
};

export const submitEditArticle = (editor) => {
  // console.log(editor.getHTML());
  tempArticleContent.value.content = editor.getHTML();
  tempArticleContent.value.create_user = JSON.parse(
    localStorage.getItem("userinfo")
  ).user_id;
  // tempArticleContent.value.attachments.length = 0;
  // console.log(
  // 	tempImgAry.value.concat(
  // 		tempArticleContent.value
  // 			.attachments
  // 	)
  // );
  deleteImgAry.value.length = 0;
  const newImgAry = tempImgAry.value.concat(
    tempArticleContent.value.attachments
  );
  tempArticleContent.value.attachments.length = 0;
  newImgAry.forEach((item) => {
    console.log(item);
    if (editor.getHTML().includes(item.target_url)) {
      console.log(item._id);
      tempArticleContent.value.attachments.push(item._id);
    } else if (item._id === tempArticleContent.value.cover_image) {
      tempArticleContent.value.attachments.push(item._id);
    } else {
      deleteImgAry.value.push(item._id);
    }
  });
  if (deleteImgAry.value.length !== 0) {
    const config: any = {
      url: `${apiUrl.url}${dbNum.value}/gcs/delete`,
      headers: {
        "Content-Type": "application/json",
      },
      method: "delete",
      data: {
        user_id: JSON.parse(localStorage.getItem("userinfo")).user_id,
        file_ids: [...deleteImgAry.value],
      },
    };
    console.log(config);

    axios(config)
      .then(async (res) => {
        await console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // const config: any = {
  // 	baseURL: `${apiUrl.url}${dbNum.value}`,
  // 	url: `/post/edit`,
  // 	headers: {
  // 		"Content-Type": "application/json"
  // 	},
  // 	method: "post",
  // 	data: { ...content }
  // data: {
  // 	title:"標題",
  // 	category: "ECG",
  // 	content: "xxxx",
  // 	lang: "zh_tw",
  // 	cover_image:
  // 		"6278c6fad178bc00433c2f59",
  // 	attachments: [
  // 		"6278c6fad178bc00433c2f59"
  // 	],
  // 	create_user:
  // 		"180f891d-2dda-4fa1-8280-35de6cbd0e0b"
  // }
  // };
  // console.log(config);
  // apiCreateArticle(config);
  // console.log(tempArticleContent.value);
  // if (
  // 	tempArticleContent.value
  // 		.cover_image === ""
  // ) {
  // 	alert("縮圖不可空白，請上傳縮圖");
  // } else {
  // 	editArticle(
  // 		tempArticleContent.value
  // 	);
  // 	objectClean(editor);
  // }
  editArticle(tempArticleContent.value);
  objectClean(editor);
  // submitBtnDisabled.value = true;
  // console.log(tempArticleContent.value);
};

export const editArticle = (content) => {
  const config: any = {
    baseURL: `${apiUrl.url}${dbNum.value}`,
    url: `/post/edit`,
    headers: {
      "Content-Type": "application/json",
    },
    method: "patch",
    data: {
      ...content,
      user_id: JSON.parse(localStorage.getItem("userinfo")).user_id,
    },
  };
  console.log(config);
  apiEditArticle(config);
};

export const loadArticle = (editor, html) => {
  editor.pasteHTML(html);
};

export const objectClean = (editor) => {
  tempArticleContent.value.post_id = "";
  tempArticleContent.value.title = "";
  tempArticleContent.value.category = "";
  tempArticleContent.value.content = "";
  tempArticleContent.value.lang = "";
  tempArticleContent.value.cover_image = "";
  tempArticleContent.value.cover_image_link = "";
  tempArticleContent.value.attachments.length = 0;
  tempArticleContent.value.create_user = "";
  editor.pasteHTML(tempArticleContent.value);
  // console.log(editor);
  // console.log(content);
};

export const enableArticle = (id, isEnabled) => {
  const config: any = {
    baseURL: `${apiUrl.url}${dbNum.value}`,
    url: `/post/${
      JSON.parse(localStorage.getItem("userinfo")).user_id
    }/${id}/${isEnabled}`,
    headers: {
      "Content-Type": "application/json",
    },
    method: "patch",
  };
  apiEnableArticle(config);
};

export const deleteArticle = (id) => {
  const config: any = {
    baseURL: `${apiUrl.url}${dbNum.value}`,
    url: `/post/${JSON.parse(localStorage.getItem("userinfo")).user_id}/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
    method: "delete",
  };
  apiDeleteArticle(config);
};
// JSON.parse(
// localStorage.getItem("userinfo")
// ).user_id
export const getImageList = () => {
  const config: any = {
    baseURL: `${apiUrl.url}${dbNum.value}`,
    url: `/gcs/find/all`,
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data: {
      user_id: JSON.parse(localStorage.getItem("userinfo")).user_id,
      start_num: 1,
      end_num: 100,
      order_by: "desc",
    },
  };
  apiGetImageList(config);
};

export const getCategoriesList = (lang) => {
  const config: any = {
    baseURL: `${apiUrl.url}${dbNum.value}`,
    url: `/config/post/Category/${lang}`,
    headers: {
      "Content-Type": "application/json",
    },
    method: "get",
  };
  apiGetCategoriesList(config);
};

export const addCategory = (data) => {
  const config: any = {
    baseURL: `${apiUrl.url}${dbNum.value}`,
    url: `/config/post/category`,
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data: {
      category_name: data.content,
      lang: data.lang,
    },
  };
  apiAddCategory(config);
  // console.log(config);
};
export const deleteCategory = (lang, id) => {
  const config: any = {
    baseURL: `${apiUrl.url}${dbNum.value}`,
    url: `/config/post/category/`,
    headers: {
      "Content-Type": "application/json",
    },
    method: "delete",
    data: {
      lang: lang,
      category_id: id,
    },
  };
  console.log(config);

  apiDeleteCategory(config);
};

export const editCategory = (newCategory, lang, id) => {
  const config: any = {
    baseURL: `${apiUrl.url}${dbNum.value}`,
    url: `/config/post/category/`,
    headers: {
      "Content-Type": "application/json",
    },
    method: "patch",
    data: {
      lang: lang,
      category_id: id,
      category_name: newCategory,
    },
  };
  console.log(config);
  apiEditCategory(config);
};
