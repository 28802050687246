import apiUrl from "../../api_url.global";
import {
	apiRequestCalendarTable,
	apiRequestCalendarMark
} from "@/composition/api";
import { dbNum } from "@/composition/store";
export const requestCalendarTableFn = () => {
	const requestCalendarTable = async (
		start,
		end
	) => {
		const config: any = {
			baseURL: `${apiUrl.url}${dbNum.value}`,
			url: "/calendar/user",
			headers: {
				"Content-Type":
					"application/json"
			},
			method: "post",
			data: {
				user_id: JSON.parse(
					localStorage.getItem(
						"userinfo"
					)
				).user_id,
				start_date: start,
				end_date: end,
				order_by: "asc"
			}
		};
		console.log(config);
		apiRequestCalendarTable(config);
	};
	const requestCalendarMark = async (
		page
	) => {
		console.log(page);

		// console.log("hello World");
		const config: any = {
			baseURL: `${apiUrl.url}${dbNum.value}`,
			url: "/calendar/user",
			headers: {
				"Content-Type":
					"application/json"
			},
			method: "post",
			data: {
				user_id: JSON.parse(
					localStorage.getItem(
						"userinfo"
					)
				).user_id,
				start_date: new Date(
					page.year,
					page.month - 1
				).toISOString(),
				end_date: new Date(
					page.year,
					page.month
				).toISOString(),
				order_by: "asc"
			}
		};
		console.log(config);
		apiRequestCalendarMark(config);
	};
	return {
		requestCalendarTable,
		requestCalendarMark
	};
};
