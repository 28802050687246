// test

import { ref } from "vue";
import router from "../router/index";
import apiUrl from "../../api_url.global";
import { apiUserLogin, apiUserLogout } from "@/composition/api";
import {
  tableData,
  token,
  dbNum,
  isLogin,
  veriFied,
  userphone,
  diagnosesNameShow,
  adminNameShow,
  calendarNameShow,
} from "@/composition/store";

export const loginFn = () => {
  const identifier = ref("");
  const password = ref("");
  const login = (phone: string, password: string, dbNum: string) => {
    // const loginConfig: any = {
    // 	url: '/login',
    // 	method: 'post',
    // 	data: {
    // 		identifier: phone,
    // 		password: password
    // 	}
    // };
    // if (dbNum === 'admin') {
    // 	loginConfig.baseURL =
    // 		apiUrl.url + 'v2';
    // 	loginConfig.headers = {
    // 		'Content-Type':
    // 			'application/json',
    // 		platform: 'web'
    // 	};
    // } else {
    // 	loginConfig.baseURL =
    // 		apiUrl.url + dbNum;
    // 	loginConfig.headers = {
    // 		'Content-Type':
    // 			'application/json',
    // 		platform:
    // 			dbNum === 'v1'
    // 				? 'web'
    // 				: 'mobile'
    // 	};
    // }
    const loginConfig: any = {
      baseURL: `${apiUrl.url}${dbNum}`,
      url: "/login",
      headers: {
        "Content-Type": "application/json",
        platform: "web",
      },
      method: "post",
      data: {
        identifier: phone,
        password: password,
      },
    };

    // const loginConfig: any = {
    // 	baseURL: apiUrl.url + dbNum,
    // 	url: "/login",
    // 	headers: {
    // 		"Content-Type":
    // 			"application/json",
    // 		platform:
    // 			dbNum === "v1"
    // 				? "web"
    // 				: "mobile"
    // 	},
    // 	method: "post",
    // 	data: {
    // 		identifier: phone,
    // 		password: password
    // 	}
    // };
    // const loginConfig = {
    // 	url:
    // 		"https://dev.intelliances.com/broker/medical/v2/login",
    // 	method: "post",
    // 	headers: {
    // 		"Content-Type":
    // 			"application/json",
    // 		platform: "mobile"
    // 	},
    // 	data: {
    // 		identifier: phone,
    // 		password: password
    // 	}
    // };
    console.log(loginConfig);
    if (veriFied.value === true) {
      apiUserLogin(loginConfig);
    } else {
      return;
    }
  };

  const logout = () => {
    if (dbNum.value === "v2") {
      const config: any = {
        baseURL: `${apiUrl.url}${dbNum.value}`,
        url: "/logout",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
        method: "post",
      };
      console.log(config);

      apiUserLogout(config);
    } else {
      removeLocalAndSession();
    }
  };
  const removeLocalAndSession = () => {
    window.localStorage.removeItem("userinfo");
    window.localStorage.removeItem("access_token");
    window.localStorage.removeItem("dbNum");
    window.sessionStorage.removeItem("selectData");
    window.sessionStorage.removeItem("selectPhone");
    tableData.data.length = 0;
    localStorage.setItem("isLogin", JSON.stringify(false));
    isLogin.value = false;
    veriFied.value = false;
    userphone.value = "";
    window.setTimeout(() => {
      router.push({
        path: `/${dbNum.value}`,
      });
    }, 1000);
    diagnosesNameShow.value = false;
    adminNameShow.value = false;
    calendarNameShow.value = false;
    console.log(
      diagnosesNameShow.value,
      adminNameShow.value,
      calendarNameShow.value
    );
    localStorage.removeItem("navBarShow");
  };
  return {
    identifier,
    password,
    isLogin,
    token,
    login,
    logout,
    dbNum,
    removeLocalAndSession,
  };
};
