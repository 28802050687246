import { createApp } from 'vue';
// import { createI18n } from 'vue-i18n';
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import App from './App.vue';
import router from './router';

import '@/assets/custom.scss';
import 'bootstrap';
import i18n from './i18n';

// type MessageSchema = typeof en;

// const i18n = createI18n<
// 	[MessageSchema],
// 	'en-US' | 'zh-TW' | 'zh-CN'
// >({
// 	legacy: false,
// 	locale: 'zh-TW',
// 	allowComposition: true,
// 	fallbackLocale: 'zh-TW',
// 	messages: {
// 		'zh-TW': tw,
// 		'en-US': en,
// 		'zh-CN': cn
// 	}
// });

createApp(App)
	.use(i18n)
	.use(router)
	.component('Loading', Loading)
	.mount('#app');
